import Router from 'next/router';
import { defaultCheckout, checkoutVar, defaultSwiftPromoVar } from '@services/graphql/cache';
import { checkoutKeyCookies, checkoutKeyLocalStorage, swiftPromotionVarLocalStorage } from '@config';
import Cookies from 'js-cookie';

export const getFallbackUrl = ({ config }) => {
    if (config?.ecpErrorCallbackUrl) {
        window.location.href = config.ecpErrorCallbackUrl;
    } else if (config && config.failCallback) {
        window.location.href = config.failCallback;
    } else if (config?.ecpCallbackUrl) {
        window.location.href = config.ecpCallbackUrl;
    } else if (config?.hostname) {
        window.location.href = config.hostname;
    } else if (document.referrer) {
        window.location.href = document.referrer;
    } else {
        Router.push('/401');
    }
};

export const resetData = async () => {
    localStorage.setItem(checkoutKeyLocalStorage, JSON.stringify(defaultCheckout));
    localStorage.setItem(swiftPromotionVarLocalStorage, JSON.stringify(defaultSwiftPromoVar));
    checkoutVar(defaultCheckout);
    const key = ['paymentData', 'orderId', 'flagGuest', 'planId', 'stripeCustId', 'stripePaymentIntent'];
    key.forEach((item) => {
        Cookies.remove(checkoutKeyCookies[item]);
    });
};

export default {
    getFallbackUrl,
};
